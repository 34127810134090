import React from 'react'
import LightboxLayout from '../../components/lightboxLayout'

const Page = () => {
  return (
    <LightboxLayout>
      <h2>DENTAL EXAMINATION</h2>
      <h3>What is it?</h3>
      <p>A dental examination shows how healthy your mouth it. After the examination, you and your dentist can discuss the results and plan the best way to deal with any problems.</p>
      <h3>What will my dentist do?</h3>
      <p>As well as looking in your mouth, the dentist will ask you some questions.</p>
      <p>These may include:</p>
      <ul>
        <li>Why you've come for an examination</li>
        <li>Any problems you've noticed (such as pain or sensitivity)</li>
        <li>Your general health and any medicines you might be taking (because these can affect your dental care)</li>
        <li>Your diet (because sugary snack and drinks can cause tooth decay, and because a balanced diet is important to your general health and resistance to disease)</li>
        <li>How you clean your teeth (because correct cleaning helps prevent tooth decay and gum disease)</li>
        <li>Tobacco and alcohol use (because both can harm your mouth as well as your general health)</li>
      </ul>
      <p>Some of the questions will depend on what the dentist sees in your mouth</p>
      <p>You should expect your dentist to:</p>
      <ul>
        <li>look at your face and neck to see that they are healthy</li>
        <li>feel under your jaw</li>
        <li>look inside your mouth, at your tongue, your cheeks and lips, the roof of your mouth and the back of your throat (places where there might be a problems that you can't see or feel)</li>
        <li>look at your teeth and gums to see whether they are healthy or whether there are signs of decay, damage or gum disease (for children, your dentist will also look at tooth and jaw development in case orthodontic treatment might be needed now or later)</li>
        <li>compare your mouth now with how it was when they last saw you</li>
        <li>decide whether they need more information from x-rays, or from plaster models showing how your teeth bite together</li>
        <li>tell you about any treatment you need, explaining the choices and whether there will be any cost</li>
      </ul>
      <p>The explanation may take longer if you are seeing a new dentist for the first time.</p>

      <h3>What  are  the benefits?</h3>
      <ul>
        <li>Regular examinations mean that your dentist can spot problems and correct them early before treatment becomes complicated</li>
        <li>A thorough examination helps you and your dentist look after your mouth and prevent future problems. The dentist will explain the options and then you can decide together what will be best for you.</li>
      </ul>
    </LightboxLayout>
  )
}

export default Page